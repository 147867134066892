import { asyncRoute, convertToAfterJsRoutes, createAsyncComponent } from "@infrastructure/routes/routes"
import { afterJsRoutes as orgRoutes } from "@organizer/routes"

export const routes = {
  home: asyncRoute("/", () => import(/* webpackChunkName: "web-home" */ "./home/HomePage"), "web-home"),
  allRaces: asyncRoute("/vsechny-zavody", () => import(/* webpackChunkName: "web-all-races" */ "./all-races/ActiveRacesPage"), "web-all-races"),
  archivedRaces: asyncRoute("/probehle-zavody", () => import(/* webpackChunkName: "web-archived-races" */ "./all-races/ArchivedRacesPage"), "web-archived-races"),
  race: asyncRoute(
    "/zavod/:raceSlug/:subraceSlug?",
    () => import(/* webpackChunkName: "web-race-detail" */ "./race/RacePage"),
    "web-race-detail",
    (raceSlug: string, subraceSlug?: string) => `/zavod/${raceSlug}/${subraceSlug || ""}`
  ),
  series: asyncRoute(
    "/serial/:seriesSlug",
    () => import(/* webpackChunkName: "web-series-detail" */ "./series/SeriesPage"),
    "web-series-detail",
    (seriesSlug: string) => `/serial/${seriesSlug}`
  ),
  startlist: asyncRoute(
    "/startovni-listina/:raceSlug/:subraceSlug",
    () => import(/* webpackChunkName: "web-startlist" */ "./startlist/StartlistPage"),
    "web-startlist",
    (raceSlug: string, subraceSlug: string) => `/startovni-listina/${raceSlug}/${subraceSlug}`
  ),
  results: asyncRoute(
    "/vysledky/:raceSlug/:subraceSlug",
    () => import(/* webpackChunkName: "web-results" */ "./results/ResultsPage"),
    "web-results",
    (raceSlug: string, subraceSlug: string) => `/vysledky/${raceSlug}/${subraceSlug}`
  ),
  registration: asyncRoute(
    "/registrace/:raceSlug/:subraceSlug",
    () => import(/* webpackChunkName: "web-reg" */ "./registration/RegistrationPage"),
    "web-reg",
    (raceSlug: string, subraceSlug: string) => `/registrace/${raceSlug}/${subraceSlug}`
  ),
  onPlaceRegistration: asyncRoute(
    "/registrace-na-miste/:subraceSlug?",
    () => import(/* webpackChunkName: "web-onplace-reg" */ "./registration/OnPlaceRegistrationPage"),
    "web-onplace-reg",
    (subraceSlug: string | undefined) => `/registrace-na-miste${subraceSlug ? `/${subraceSlug}` : ""}`
  ),
  updateRegistration: asyncRoute(
    "/upravit-registraci/:guid/:token",
    () => import(/* webpackChunkName: "web-update-reg" */ "./registration/UpdateRegistrationPage"),
    "web-update-reg",
    (guid: string, token: string) => `/upravit-registraci/${guid}/${token}`
  ),
  payment: asyncRoute(
    "/platba/:guid",
    () => import(/* webpackChunkName: "web-payment" */ "./checkout/PaymentPage"),
    "web-payment",
    (guid: string) => `/platba/${guid}`
  ),
  stripeCancel: asyncRoute(
    "/stripe-cancel/:guid",
    () => import(/* webpackChunkName: "web-stripe-cancel" */ "./checkout/StripeCancelPage"),
    "web-stripe-cancel",
    (guid: string) => `/stripe-cancel/${guid}`
  ),
  stripeSuccess: asyncRoute(
    "/stripe-ok/:guid/:sessionId",
    () => import(/* webpackChunkName: "web-stripe-ok" */ "./checkout/StripeSuccessPage"),
    "web-stripe-ok",
    (guid: string, sessionId: string) => `/stripe-ok/${guid}/${sessionId}`
  ),
  confirm: asyncRoute(
    "/potvrzeni/:guid",
    () => import(/* webpackChunkName: "web-confirm" */ "./registration/ConfirmPage"),
    "web-confirm",
    (guid: string) => `/potvrzeni/${guid}`
  ),
  referrals: asyncRoute(
    "/pozvanka/:guid",
    () => import(/* webpackChunkName: "web-referrals" */ "./registration/referrals/ReferralsPage"),
    "web-referrals",
    (guid: string) => `/pozvanka/${guid}`
  ),
  updateConfirm: asyncRoute(
    "/uprava-potvrzeni/:guid",
    () => import(/* webpackChunkName: "web-update-confirm" */ "./registration/UpdateConfirmPage"),
    "web-update-confirm",
    (guid: string) => `/uprava-potvrzeni/${guid}`
  ),
  onPlaceConfirm: asyncRoute(
    "/potvrzeni-na-miste/:guid",
    () => import(/* webpackChunkName: "web-onplace-confirm" */ "./registration/OnPlaceConfirmPage"),
    "web-onplace-confirm",
    (guid: string) => `/potvrzeni-na-miste/${guid}`
  ),
  contact: asyncRoute("/kontakt", () => import(/* webpackChunkName: "web-contact" */ "./contact/ContactPage"), "web-contact"),
  forOrganizers: asyncRoute("/pro-organizatory", () => import(/* webpackChunkName: "web-for-organizers" */ "./for-organizers/ForOrganizersPage"), "web-for-organizers"),
  feedbackHornettlon: asyncRoute("/zkusenosti-organizatoru/hornettlon", () => import(/* webpackChunkName: "hornettlon" */ "./organizers-references/HornettlonPage"), "hornettlon"),
  feedbackAskDecin: asyncRoute("/zkusenosti-organizatoru/ask-decin", () => import(/* webpackChunkName: "ask-decin" */ "./organizers-references/AskDecinPage"), "ask-decin"),
  feedbackLudgerovice: asyncRoute(
    "/zkusenosti-organizatoru/bezecky-klub-ludgerovice",
    () => import(/* webpackChunkName: "ask-decin" */ "./organizers-references/LudgerovicePage"),
    "bezecky-klub-ludgerovice"
  ),
  terms: asyncRoute("/podminky", () => import(/* webpackChunkName: "web-tac" */ "./terms-and-conditions/TermsAndConditionsPage"), "web-tac"),
  privacy: asyncRoute("/ochrana-soukromi", () => import(/* webpackChunkName: "web-privacy" */ "./terms-and-conditions/PrivacyPage"), "web-privacy"),
  changelog: asyncRoute("/novinky", () => import(/* webpackChunkName: "web-changelog" */ "./changelog/ChangelogPage"), "web-changelog"),
  racer: asyncRoute(
    "/zavodnik/:guid/:token?",
    () => import(/* webpackChunkName: "web-racer" */ "./racer/racer-page/RacerPage"),
    "web-racer",
    (guid: string, token: string) => `/zavodnik/${guid}/${token}`
  ),
  transferRegistration: asyncRoute(
    "/prevod-registrace/:guid/:regguid/:token?",
    () => import(/* webpackChunkName: "web-transfer-registration" */ "./racer/transfer-registration/TransferRegistrationPage"),
    "web-transfer-registration",
    (guid: string, regguid: string, token: string) => `/prevod-registrace/${guid}/${regguid}/${token}`
  ),
  transferRegistrationApproval: asyncRoute(
    "/prevod-registrace-potvrzeni/:transferguid",
    () => import(/* webpackChunkName: "web-transfer-registration-approval" */ "./racer/transfer-registration/TransferRegistrationApprovalPage"),
    "web-transfer-registration-approval",
    (transferGuid: string) => `/prevod-registrace-potvrzeni/${transferGuid}`
  ),
  transferRegistrationCompleted: asyncRoute(
    "/prevod-registrace-hotovo/:transferguid",
    () => import(/* webpackChunkName: "web-transfer-registration-completed" */ "./racer/transfer-registration/TransferRegistrationCompletedPage"),
    "web-transfer-registration-completed",
    (transferGuid: string) => `/prevod-registrace-hotovo/${transferGuid}`
  ),
  forRacers: asyncRoute("/pro-zavodniky", () => import(/* webpackChunkName: "web-send-info" */ "./racer/send-info/SendInfoPage"), "web-send-info"),
  startNumber: asyncRoute(
    "/startovni-cislo/:guid",
    () => import(/* webpackChunkName: "web-start-number" */ "./start-number/StartNumberPage"),
    "web-start-number",
    (guid: string) => `/startovni-cislo/${guid}`
  ),
  error: asyncRoute("/chyba", () => import(/* webpackChunkName: "web-error-page" */ "./layout/ErrorPage"), "web-error-page")
}

export const afterJsRoutes = [
  ...convertToAfterJsRoutes(routes),
  ...orgRoutes,
  {
    component: createAsyncComponent(() => import(/* webpackChunkName: "web-404" */ "./layout/NotFoundPage"), "web-404")
  }
]
